import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NIFTY_APPLICATIONID_KEY } from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { AccountManager } from '../core.types';

@Injectable({
  providedIn: 'root',
})
export class AccountManagersService {
  private readonly route = `${environment.apiUrl}/accountManagers`;

  constructor(private httpClient: HttpClient) {}

  getAccountManagers(): Observable<AccountManager[]> {
    return this.httpClient.get<AccountManager[]>(this.route, {
      params: new HttpParams().set('applicationId', NIFTY_APPLICATIONID_KEY),
    });
  }

  getAccountManager(id: string): Observable<AccountManager> {
    return this.httpClient.get<AccountManager>(`${this.route}/${id}`);
  }

  generateAccountManager(accountManager: AccountManager) {
    return this.httpClient.post<{ id: string }>(this.route, {
      ...accountManager,
      applicationId: NIFTY_APPLICATIONID_KEY,
    });
  }

  updateAccountManager(id: string, accountManager: AccountManager) {
    return this.httpClient.put<{ id: string }>(`${this.route}/${id}`, {
      ...accountManager,
      applicationId: NIFTY_APPLICATIONID_KEY,
    });
  }

  activateAccountManager(id: string) {
    return this.httpClient.patch(`${this.route}/${id}/activate`, {});
  }

  deactivateAccountManager(id: string) {
    return this.httpClient.patch(`${this.route}/${id}/deactivate`, {});
  }

  assignCountryToAccountManager(id: string, country: string) {
    return this.httpClient.patch(`${this.route}/${id}/assignCountry`, {
      country,
    });
  }

  unassignCountryToAccountManager(id: string, country: string) {
    return this.httpClient.patch(`${this.route}/${id}/unassignCountry`, {
      country,
    });
  }
}
